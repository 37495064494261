let resizeTimeout
const tabsDeployments = document.querySelectorAll('.tabs-deployments')

const setMenuHeight = () => {
    if (window.innerWidth > 991) {
        if (tabsDeployments.length > 0) {
            for (const tab of tabsDeployments) {
                const nav = tab.querySelector('.nav.nav-pills')
                nav.style.top = '20px'
            }
        }
    }
}

const initMenuSticky = () => {
    setMenuHeight()

    window.addEventListener('resize', () => {
        if (resizeTimeout) {
            clearTimeout(resizeTimeout)
        }
        resizeTimeout = setTimeout(() => {
            setMenuHeight()
        }, 500)
    })
}

export { initMenuSticky }
